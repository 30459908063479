import React from "react";
import { graphql, PageProps } from "gatsby";
import Article, { ArticleInterface } from "../../components/Article";
import Layout from "../../components/Layout";
import { LocalisedPageContext } from "../../types/PageTypes";
import SEO from "../../components/Seo";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { LocalizedContextInterface } from "../../types/SanityTypes";

// Expected results of the graphql query made by this component.
type ArticleQueryProps = {
  sanityArticle: ArticleInterface["data"];
} & LocalizedContextInterface;

// Value of `context` in the object passed to the `createPage()` function used to create this page.
type ArticlePageContext = LocalisedPageContext;

export const query = graphql`
  query ($_id: String, $language: String, $market: String) {
    sanityArticle(_id: { eq: $_id }) {
      ...ArticleFieldsFull
    }
    ...LocalizedContext
  }
`;

const ArticlePage = (props: PageProps<ArticleQueryProps, ArticlePageContext>) => {
  const article = props.data.sanityArticle;
  const pageTitle = article.seo?.metaTitle || article.headline;
  const parentPages = article.parentSection?.parentSection
    ? [article.parentSection.parentSection.name, article.parentSection.name]
    : article.parentSection
    ? [article.parentSection.name]
    : [];
  const seoDescription = article.seo?.metaDescription || article._rawSubheading[0].children[0].text;
  const { htmlLang } = useSiteMetadata(props.pageContext.language);

  console.log("SEO: ", article.seo);

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"article"}
      parentPages={parentPages}
    >
      <StructuredData
        type={"Article"}
        headline={article.headline}
        description={seoDescription}
        slug={article.slug.current}
        image={{
          url: (article._rawHeroImage.asset as Record<string, unknown>).url as string
        }}
        data={article}
        customData={article.seo.jsonld}
        language={htmlLang}
      />
      <SEO
        title={pageTitle}
        description={seoDescription}
        imageUrl={article._rawHeroImage.asset.url}
        imageAlt={article._rawHeroImage.alt}
      />
      <Article
        data={article}
        data-testid="article"
        articleRecommendationLabel={props.data.sanityLabels?.articleSliderHeading as string}
      />
    </Layout>
  );
};

export default ArticlePage;
